import Control from '@components/Control'
import clsx from 'clsx'
import { useKeenSlider } from 'keen-slider/react'
import React, { useState } from 'react'
import FontAwesomeIcon from '../FontAwesomeIcon'
import './horizontal-slider.css'

export default function HorizontalSlider({ children, className }: { children: React.ReactNode; className?: string }) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [slideCount, setSlideCount] = useState(0)
  const [slidesPerView, setSlidesPerView] = useState<any>(3)
  const [isLoaded, setIsLoaded] = useState(false)
  const [refCallback, slider] = useKeenSlider({
    initial: 0,
    loop: false,
    renderMode: 'performance',
    slides: {
      perView: 2,
      spacing: 16,
    },
    breakpoints: {
      '(min-width: 420px)': {
        slides: {
          perView: 3,
          spacing: 30,
        },
      },
      '(min-width: 1024px)': {
        slides: {
          perView: 4,
          spacing: 30,
        },
      },
      '(min-width: 1280px)': {
        slides: {
          perView: 5,
          spacing: 30,
        },
      },
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    optionsChanged(s) {
      setSlidesPerView(typeof s.options!.slides === 'object' && s.options.slides!.perView!)
    },
    created(s) {
      setIsLoaded(true)
      setSlideCount(s.slides.length)
      setSlidesPerView(typeof s.options!.slides === 'object' && s.options.slides!.perView!)
    },
  })

  return (
    <>
      <ul ref={refCallback} className={clsx('horizontal-slider keen-slider', className)} data-loaded={isLoaded}>
        {children}
      </ul>
      <div className="horizontal-slider__controls desktop:!u-hidden">
        <Control
          size="sm"
          type="outline"
          icon="arrow-left"
          disabled={currentSlide === 0}
          onClick={() => slider?.current?.prev()}
        >
          <span className="u-sr-only">Previous Slide</span>
        </Control>
        <Control
          size="sm"
          type="outline"
          icon="arrow-right"
          disabled={currentSlide + slidesPerView > slideCount - 1}
          onClick={() => slider?.current?.next()}
        >
          <span className="u-sr-only">Next Slide</span>
        </Control>
      </div>
    </>
  )
}
